import { APP_DEVICE_ID_STORAGE_KEY } from 'configs/android'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { useSatuEventTracker } from 'utils/satuEvent'
import AndroidHandler from 'utils/androidHandler'

const SATU_EVENT_NAME = 'guru_core_pwa_event_tracker_v1'
const TRACKER_EVENT_NAME = 'GURU_CORE_APP'
const TRACKER_SCREEN_NAME = 'PMM_LANDING_PAGE'
const DEFAULT_TIMEOUT = 5000 // 5 seconds

type TSETrackEventDefaultAtributes = {
  screen_name: string
  is_login: [boolean, 'bool']
  is_webview: [boolean, 'bool']
  email: string
}
export const useSETrackEvent = () => {
  const [, , , getAppDeviceIdValue] = useLocalStorage(
    APP_DEVICE_ID_STORAGE_KEY,
    null
  )

  const appDeviceID = getAppDeviceIdValue()
  const hasAppDeviceID = Boolean(appDeviceID)
  const androidHandler = AndroidHandler()
  const APP_LOGIN_KEY = 'app-login'
  const [userData] = useLocalStorage(APP_LOGIN_KEY, false)
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()

  const SETrackEventDefaultAtributes: TSETrackEventDefaultAtributes = {
    screen_name: TRACKER_SCREEN_NAME,
    is_login: [!!userData?.user, 'bool'],
    is_webview: [androidHandler.enabled, 'bool'],
    email: userData?.user?.email ?? null,
  }

  const SETrackEventLandingPage = (
    activity_type: string,
    additionalAttributes?: Record<string, any>
  ): void => {
    SETrackEvent({
      eventName: SATU_EVENT_NAME,
      eventParams: {
        activity_type,
        event_name: TRACKER_EVENT_NAME,
        user_id: userData?.user?.id,
        ...(hasAppDeviceID ? { device_id: appDeviceID } : {}),
        attributes: SETransformAttrs({
          ...SETrackEventDefaultAtributes,
          event_trigger: TRACKER_SCREEN_NAME,
          ...additionalAttributes,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }
  return {
    SETrackEventLandingPage,
  }
}
