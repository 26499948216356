import { useEffect, useRef } from 'react'
import { LazyMotion, m } from 'framer-motion'

import { CONTENT_PLAYLISTS_DATA } from 'app/LandingV2Page/constants'
import { ContentPlaylistItem } from 'app/LandingV2Page/components/ContentPlaylistItem'
import { useSETrackEvent } from 'app/LandingV2Page/hooks/useSETrackEvent'

const domAnimation = () =>
  import('app/LandingV2Page/utils/domAnimation').then((res) => res.default)

export const ContentPlaylists = () => {
  const { SETrackEventLandingPage } = useSETrackEvent()
  const isOnLoadTracked = useRef(false)

  useEffect(() => {
    if (!isOnLoadTracked.current) {
      isOnLoadTracked.current = true
      const urls = CONTENT_PLAYLISTS_DATA.map(({ link }) => link).join(',')
      const topicIds = CONTENT_PLAYLISTS_DATA.map(
        ({ topicId }) => topicId
      ).join(',')

      SETrackEventLandingPage('LOAD_CURATED_MATERIAL_HOMEPAGE', {
        url: urls,
        topic_id: topicIds,
      })
    }
  }, [SETrackEventLandingPage, isOnLoadTracked])

  return (
    <section
      id="landing-content-playlists"
      role="contentinfo"
      aria-label="Content Playlists"
      className="flex flex-col items-center px-4 py-10 mx-auto w-full max-w-7xl bg-white md:px-6 2xl:px-0 2xl:py-20"
    >
      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ opacity: 0, y: '20%' }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          viewport={{ once: true }}
        >
          <p className="text-[28px] md:text-[32px] leading-9 text-center font-semibold mb-2 md:mb-4">
            Materi terpilih untuk mendukung kontribusi Anda di sekolah
          </p>
        </m.div>
      </LazyMotion>

      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ opacity: 0, y: '10%' }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          viewport={{ once: true }}
        >
          <p className="mb-10 max-w-xl text-base leading-6 text-center md:text-xl md:leading-7">
            PMM memberikan akses ke beragam artikel dan video pembelajaran serta
            contoh-contoh Perangkat Ajar.
          </p>
        </m.div>
      </LazyMotion>

      <div className="grid grid-cols-12 gap-x-2 gap-y-10 w-full md:gap-x-6">
        {CONTENT_PLAYLISTS_DATA.map((data, index) => (
          <LazyMotion
            features={domAnimation}
            key={`landing-content-playlist-${index}`}
          >
            <m.article
              className="flex col-span-6 h-full lg:col-span-3"
              initial={{ opacity: 0, y: '5%' }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                ease: 'easeOut',
                duration: 0.3,
                delay: 0.1 + index * 0.3,
              }}
              viewport={{ once: true }}
            >
              <ContentPlaylistItem data={data} />
            </m.article>
          </LazyMotion>
        ))}
      </div>
    </section>
  )
}
