import { Text } from '@wartek-id/text'

export const FAQ_CONTENTS = [
  {
    title:
      'Apakah saya boleh menggunakan platform Merdeka Mengajar meskipun belum menerapkan Kurikulum Merdeka?',
    content: (
      <>
        Tentu saja.{' '}
        <b>Platform Merdeka Mengajar disediakan bagi guru dan kepala sekolah</b>{' '}
        untuk mendapatkan referensi, inspirasi, dan pemahaman tentang Kurikulum
        Merdeka, serta mendukung proses belajar bersama di kelas, sehingga
        pembelajaran dapat menjadi lebih kreatif, bermakna, dan berpusat pada
        murid. Anda dapat menggunakan platform Merdeka Mengajar sebagai sumber
        mengajar, selama diselaraskan dengan kebutuhan murid dan tujuan
        pembelajaran.
      </>
    ),
  },
  {
    title: 'Apakah peserta didik bisa menggunakan platform Merdeka Mengajar?',
    content: (
      <>
        Tidak. <b>Peserta didik tidak diberikan akses</b> menggunakan platform
        Merdeka Mengajar. Platform Merdeka Mengajar disediakan khusus bagi guru
        dan kepala sekolah, untuk memperoleh informasi terkait Kurikulum
        Merdeka, serta referensi perangkat ajar dan asesmen untuk mengajar di
        kelas.
      </>
    ),
  },
  {
    title:
      'Apakah aplikasi Merdeka Mengajar dapat diinstal ke semua jenis gawai?',
    content: (
      <>
        Tidak. Platform Merdeka Mengajar hanya dapat diinstal di gawai berbasis{' '}
        <b>Android 5 (Lollipop)</b> ke atas. Bagi Anda yang tidak menggunakan
        gawai Android, dapat mengakses situs web platform Merdeka Mengajar (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://guru.kemdikbud.go.id/"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            https://guru.kemdikbud.go.id/
          </Text>
        </a>
        ) melalui browser laptop, komputer, maupun ponsel (iOS)
      </>
    ),
  },
  {
    title: (
      <>
        Akun apa yang dapat digunakan untuk masuk (<em>login</em>) ke platform
        Merdeka Mengajar?
      </>
    ),
    content: (
      <>
        Anda dapat masuk (login) ke platform Merdeka Mengajar menggunakan 2 akun
        berikut:
        <br />
        <ol className="ml-6 list-decimal list-outside">
          <li>
            <b>Akun belajar.id</b> (contoh:{' '}
            <span className="break-all">
              <em>namaakun@guru.smp.belajar.id</em>
            </span>
            ) bagi guru dan kepala sekolah yang berada di bawah naungan
            Kemendikbudristek dan terdata di Dapodik.
          </li>
          <li>
            <b>Akun Madrasah</b> (contoh:{' '}
            <span className="break-all">
              <em>namaakun@madrasah.kemenag.go.id</em>
            </span>
            ) bagi guru madrasah yang berada di bawah naungan Kemenag.
          </li>
        </ol>
        <br />
        Untuk mengetahui domain Akun belajar.id yang dapat mengakses platform
        Merdeka Mengajar, silakan lihat di{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/8829756246937-Daftar-Domain-Akun-belajar-id-yang-Bisa-Masuk-Login-ke-Platform-Merdeka-Mengajar"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            artikel ini
          </Text>
        </a>
        .
      </>
    ),
  },
  {
    title: (
      <>
        Bagaimana cara mendapatkan <b>Akun belajar.id</b> dan{' '}
        <b>akun Madrasah?</b>
      </>
    ),
    content: (
      <>
        <b>Cara Mendapatkan Akun belajar.id</b>
        <br />
        Akun belajar.id, berupa nama akun (User ID) dan kata sandi (
        <em>password</em>), bisa didapatkan secara mandiri melalui langkah
        berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>
            Buka halaman{' '}
            <a target="_blank" rel="noreferrer" href="https://belajar.id">
              <Text
                as="span"
                color="primary"
                className="!text-[16px] hover:underline"
              >
                https://belajar.id
              </Text>
            </a>
            .
          </li>
          <li>Pilih tipe pengguna.</li>
          <li>
            Masukkan Nomor Pokok Satuan Pendidikan Nasional (NPSN), lalu klik{' '}
            <b>Selanjutnya</b>.
          </li>
          <li>
            Masukkan <b>Nama Lengkap</b> dan <b>Tanggal Lahir</b> Anda sesuai
            data di Dapodik, lalu klik <b>Selanjutnya</b>.
          </li>
          <li>Informasi mengenai status Akun belajar.id Anda akan muncul.</li>
        </ol>
        <br />
        Panduan lengkap untuk mendapatkan Akun belajar.id dapat dilihat{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://belajarid.zendesk.com/hc/en-us/articles/4425331985049-Panduan-Mendapatkan-Akun-Pembelajaran-untuk-Pendidik"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            di sini
          </Text>
        </a>
        .
        <br />
        <br />
        <b>Cara Mendapatkan Akun Madrasah</b>
        <br />
        Untuk mendapatkan akun Madrasah, silakan kunjungi{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://akun.madrasahebat.id/"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            https://akun.madrasahebat.id/
          </Text>
        </a>
        .
      </>
    ),
  },
  {
    title: (
      <>
        Bagaimana cara masuk (<em>login</em>) ke platform Merdeka Mengajar?
      </>
    ),
    content: (
      <>
        Bagi Anda yang{' '}
        <b>
          belum pernah masuk (<em>login</em>) ke <em>email</em> Belajar.id atau
          akun Madrasah
        </b>
        , pastikan Anda melakukan aktivasi Akun belajar.id atau akun Madrasah.
        <ul className="ml-6 list-disc list-outside">
          <li>Cara aktivasi Akun belajar.id dapat dilihat pada halaman ini.</li>
          <li>
            Cara aktivasi akun Madrasah bisa dilihat di halaman{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://akun.madrasahebat.id/guidebook"
            >
              <Text
                as="span"
                color="primary"
                className="!text-[16px] hover:underline break-all"
              >
                https://akun.madrasahebat.id/guidebook
              </Text>
            </a>
            .
          </li>
        </ul>
        <br />
        Setelah melakukan aktivasi <em>email</em>, silakan masuk (<em>login</em>
        ) ke platform Merdeka Mengajar dengan cara berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>Buka aplikasi Merdeka Mengajar di ponsel Anda.</li>
          <li>
            Geser layar hingga ke paling bawah untuk menemukan tombol{' '}
            <b>Masuk</b>, atau buka halaman <b>Pengaturan</b>.
          </li>
          <li>
            Klik tombol <b>Masuk</b>.
          </li>
          <li>
            Masukkan atau pilih alamat email <b>Akun belajar.id</b> (contoh:{' '}
            <span className="break-all">
              <em>namaakun@guru.smp.belajar.id</em>
            </span>
            ) atau <b>akun Madrasah</b> (contoh:{' '}
            <span className="break-all">
              <em>namaakun@madrasah.kemenag.go.id</em>
            </span>
            ).
          </li>
          <li>
            Jika diminta, masukkan kata sandi (<em>password</em>) Akun
            belajar.id atau akun Madrasah.
          </li>
        </ol>
        <br />
        Untuk situs web Merdeka Mengajar, ikuti langkah yang sama seperti di
        atas. Namun, tombol <b>Masuk</b> bisa ditemukan dengan mengeklik menu
        Asesmen Murid, Perangkat Ajar, Pelatihan Mandiri, atau Komunitas.
      </>
    ),
  },
  {
    title: (
      <>
        Kata sandi (<em>password</em>) apa yang harus saya masukkan untuk masuk
        (<em>login</em>) ke platform Merdeka Mengajar?
      </>
    ),
    content: (
      <>
        Kata sandi (<em>password</em>) yang digunakan untuk mengakses platform
        Merdeka Mengajar adalah kata sandi dari Akun belajar.id atau akun
        Madrasah Anda.
      </>
    ),
  },
  {
    title: (
      <>
        Bagaimana jika saya lupa kata sandi (<em>password</em>) Akun belajar.id
        atau akun Madrasah?
      </>
    ),
    content: (
      <>
        <b>Bagi yang menggunakan Akun belajar.id:</b>
        <br />
        Jika Anda pengguna Akun belajar.id dan telah memenuhi dua kondisi
        berikut:
        <br />
        <ol className="ml-6 list-decimal list-outside">
          <li>Sesaat setelah aktivasi</li>
          <li>
            Setelah Anda menjadi pengguna aktif atau apabila Anda lupa kata
            sandi (<i>password</i>).
          </li>
        </ol>
        <br />
        Maka, Anda dapat mengganti kata sandi (<i>password</i>) Akun belajar.id
        dengan mengikuti langkah-langkah di bawah ini:
        <br />
        <ol className="ml-6 list-decimal list-outside">
          <li>
            Buka laman{' '}
            <a target="_blank" rel="noreferrer" href="https://belajar.id">
              <Text
                as="span"
                color="primary"
                className="!text-[16px] hover:underline"
              >
                https://belajar.id
              </Text>
            </a>{' '}
            kemudian klik tombol <b>Reset Password</b>
          </li>
          <li>
            Masukan nama Akun belajar.id dan tanggal lahir Anda, lalu klik{' '}
            <b>Cari Akun belajar.id</b>
          </li>
          <li>
            Pilih cara untuk mengirim kode reset kata sandi Anda:
            <br />- <b>Email:</b>
            <br />
            <div className="ml-3">
              Jika Anda ingin mengirim kode melalui <i>email</i>, maka klik{' '}
              <b>Kirim Kode ke Email Pribadi</b>. Kemudian buka pesan masuk di{' '}
              <i>email</i> pribadi Anda yang berisi tautan reset kata sandi,
              lalu klik tautan tersebut.
            </div>
            - <b>Nomor Handphone:</b>
            <br />
            <div className="ml-3">
              Jika Anda ingin mengirim kode melalui nomor <i>handphone</i>, maka
              klik <b>Kirim Kode ke Nomor Handphone</b>. Kemudian masukan kode
              OTP yang masuk ke SMS handphone Anda.
            </div>
          </li>
          <li>
            Masukan kata sandi yang baru, lalu klik <b>Ganti Password</b>
          </li>
        </ol>
        <br />
        Selain dengan cara di atas, Anda juga dapat mengganti kata sandi dengan
        menghubungi admin sekolah. Informasi lebih lanjut silakan baca{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://pusatinformasi.belajar.id/hc/en-us/articles/4433356533401"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            artikel ini
          </Text>
        </a>
        .
        <br />
        <br />
        <b>Bagi yang menggunakan akun Madrasah:</b>
        <br />
        Jika Anda lupa kata sandi (<i>password</i>) akun madrasah, dapat
        menghubungi halaman{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://akun.madrasahebat.id/"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            https://akun.madrasahebat.id/
          </Text>
        </a>{' '}
        kemudian klik <b>Kontak Helpdesk</b> yang ada di bagian atas halaman
        tersebut.
      </>
    ),
  },
  {
    title:
      'Mengapa saya tidak dapat mengakses beberapa menu di platform Merdeka Mengajar?',
    content: (
      <>
        Untuk mengakses semua menu yang ada di platform Merdeka Mengajar,
        silakan lakukan pengecekan berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>Pastikan koneksi internet stabil,</li>
          <li>
            Pastikan Anda memiliki aplikasi Merdeka Mengajar versi terbaru, atau
          </li>
          <li>
            Pastikan Anda sudah masuk (login) menggunakan Akun belajar.id atau
            akun Madrasah.
          </li>
        </ol>
        <br />
        Jika kendala masih Anda alami, lakukan langkah-langkah berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>Hapus cache pada ruang penyimpanan aplikasi, atau</li>
          <li>Hapus dan instal ulang aplikasi Merdeka Mengajar.</li>
        </ol>
      </>
    ),
  },
  {
    title: (
      <>
        Saya sudah masuk (<em>login</em>) ke platform Merdeka Mengajar, mengapa
        layar tetap kosong/error?
      </>
    ),
    content: (
      <>
        Hal tersebut dapat terjadi karena koneksi internet yang tidak stabil
        atau aplikasi yang belum diperbarui.
        <br />
        <br />
        Untuk itu, silakan lakukan pengecekan berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>
            Pastikan koneksi internet stabil, lalu coba buka kembali platform
            Merdeka Mengajar.
          </li>
          <li>
            Pastikan Anda memiliki aplikasi Merdeka Mengajar versi terbaru.
          </li>
        </ol>
        <br />
        Untuk melakukan pembaruan versi aplikasi Merdeka Mengajar, klik{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://bit.ly/platformmerdekamengajar"
        >
          <Text
            as="span"
            color="primary"
            className="!text-[16px] hover:underline"
          >
            https://bit.ly/platformmerdekamengajar
          </Text>
        </a>{' '}
        lalu klik <b>Update</b>. Selain itu, Anda juga dapat mengikuti cara
        berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>Buka Google Play Store.</li>
          <li>
            Ketik “<b>Merdeka Mengajar</b>” di kolom pencarian, lalu klik{' '}
            <b>Update</b>.
          </li>
        </ol>
        <br />
        Jika keterangan yang muncul bukan <b>Update</b>, melainkan <b>Buka</b>{' '}
        atau <b>Uninstall</b>, artinya platform Merdeka Mengajar yang terpasang
        di gawai Anda sudah menggunakan versi terbaru dan tidak perlu melakukan
        pembaruan versi.
        <br />
        <br />
        Jika masih terjadi, lakukan langkah-langkah berikut:
        <ol className="ml-6 list-decimal list-outside">
          <li>Hapus cache pada ruang penyimpanan.</li>
          <li>Hapus dan instal ulang platform Merdeka Mengajar.</li>
        </ol>
      </>
    ),
  },
]
