import React from 'react'
import NextImage from 'next/image'

export const ImageFallback = (props) => {
  const { src, fallbackSrc, ...rest } = props
  const [imgSrc, setImgSrc] = React.useState(src)

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <NextImage
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc)
      }}
    />
  )
}
