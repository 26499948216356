export const STATISTICS_DATA = [
  {
    numbers: 2.6,
    suffix: ' juta+',
    caption: 'guru & tenaga kependidikan',
  },
  {
    numbers: 329,
    suffix: ' ribu+',
    caption: 'satuan pendidikan',
  },
  {
    numbers: 500,
    suffix: '+',
    caption: 'kabupaten/kota',
  },
  {
    numbers: 731,
    suffix: ' ribu+',
    caption: 'materi belajar',
  },
]
