import { USER_ID_STORAGE_KEY } from 'configs/auth'
import { getTimestampForGTag } from 'utils/datetime'

export interface GATrackEventArgs {
  eventName: Gtag.EventNames | string
  eventParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
  sendDefaultParams?: boolean
}

const getDefaultTrackerData = () => {
  const pathname = window.location.pathname
  const userData: any = !!localStorage.getItem('app-login')
    ? JSON.parse(localStorage.getItem('app-login'))
    : false
  const userId = localStorage.getItem(USER_ID_STORAGE_KEY)
  return {
    userId:
      !!userId && userId !== 'null'
        ? userId
        : (userData?.user?.id as string) ?? 'null',
    timestamp: getTimestampForGTag(),
    product: pathname.split('/')[1].replace('-', '_'),
  }
}

export const GATrackEvent = ({
  eventName,
  eventParams,
  sendDefaultParams = false,
}: GATrackEventArgs) => {
  if (typeof gtag !== 'undefined') {
    if (
      !!eventParams &&
      !!eventParams['meta'] &&
      typeof eventParams['meta'] === 'object'
    ) {
      eventParams['meta'] = JSON.stringify(eventParams['meta'])
    }

    gtag('event', eventName, {
      ...(sendDefaultParams && getDefaultTrackerData()),
      ...eventParams,
    })
  }
}
