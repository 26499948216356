import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'
import { GATrackEvent } from 'utils/googleAnalytics'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

export const useGATrackEvent = () => {
  const [userData] = useLocalStorage(APP_LOGIN_STORAGE_KEY, false)

  const GATrackEventLandingPage = ({
    activityType = '',
    eventName = 'GURU_CORE_APP',
    eventTrigger = 'HOMEPAGE',
    screenName = 'WEB_HOMEPAGE',
  }) => {
    GATrackEvent({
      eventName,
      eventParams: {
        event_trigger: eventTrigger,
        referrer_screen_name: eventTrigger,
        screen_name: screenName,
        activity_type: activityType,
        is_login: !!userData?.user,
        page_location: window.location.origin,
        page_element: null,
        user_id: userData?.user?.id ?? null,
      },
    })
  }

  return { GATrackEventLandingPage }
}
