import NextLink from 'next/link'
import NextImage from 'next/future/image'

import { Text } from '@wartek-id/text-v1'

import { useSETrackEvent } from 'app/LandingV2Page/hooks/useSETrackEvent'

interface IContentPlaylistItemProps {
  data: {
    topicId: string
    key: string
    contentCount: string
    link: string
    title: string
  }
}
export const ContentPlaylistItem = ({ data }: IContentPlaylistItemProps) => {
  const image = `/images/landing/${data.key}.jpg`
  const { SETrackEventLandingPage } = useSETrackEvent()
  return (
    <NextLink href={data.link} passHref>
      <a
        className="w-full group pb-6 flex-1 cursor-pointer border-b-4 border-b-white hover:border-b-interactive-default active:border-b-[#103178]"
        onClick={() =>
          SETrackEventLandingPage('CLICK_CURATED_MATERIAL_HOMEPAGE', {
            url: data.link,
            topic_id: data.topicId,
          })
        }
      >
        <div className="mb-3 rounded-lg">
          <NextImage
            key={image}
            src={image}
            alt={data.title}
            className="object-cover !rounded-lg opacity-100 transition duration-300 ease-in-out group-hover:opacity-90"
            sizes="(min-width: 768px) 25vw, 50vw"
            width={423}
            height={635}
          />
        </div>

        <Text
          variant="subheading"
          color="disabled"
          className="mb-1 md:mb-2 group-hover:!text-default"
        >
          {data.contentCount}
        </Text>

        <h3 className="text-lg md:text-xl leading-6 md:leading-7 font-semibold group-hover:text-interactive-default group-active:text-[#103178] line-clamp-4">
          {data.title}
        </h3>
      </a>
    </NextLink>
  )
}
