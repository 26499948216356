import React from 'react'
import clsx from 'clsx'
import { LazyMotion, m } from 'framer-motion'

import { Button } from '@wartek-id/button-v1'
import { Icon } from '@wartek-id/icon'

import { useMediaQuery } from 'app/LandingV2Page/hooks/useMediaQuery'
import { HERO_VIDEO_ID } from 'app/LandingV2Page/constants'
import { ImageFallback } from 'components/ImageFallback'
import PopupVideo from 'components/PopupVideo'

import type { PropsLandingPageTracker } from 'types/landing-page'

const domAnimation = () =>
  import('app/LandingV2Page/utils/domAnimation').then((res) => res.default)

const ImageGrid = () => {
  const { isDesktop } = useMediaQuery()
  return (
    <div className="grid max-w-full grid-cols-12 gap-0 grow-0">
      {Array(4)
        .fill('')
        .map((_, index) => {
          const imagePath = `/images/landing/header-grid-${index + 1}`
          return (
            <div
              key={`landing-header-grid-${index}`}
              className={clsx(
                'col-span-4 md:col-span-3 aspect-square relative',
                index === 3 && 'hidden md:block'
              )}
            >
              <ImageFallback
                key={imagePath}
                src={`${imagePath}.webp`}
                fallbackSrc={`${imagePath}.jpg`}
                alt={`Gambar header ${index + 1}`}
                layout="fill"
                sizes="(min-width: 768px) 25vw, 33vw"
                priority={isDesktop || index < 3}
              />
            </div>
          )
        })}
    </div>
  )
}

export const Hero = ({ clickTracker }: PropsLandingPageTracker) => {
  const [isPopupVideoOpened, setPopupVideoOpened] = React.useState(false)
  const onLearnMoreClicked = () => {
    const element = document.getElementById('landing-content-playlists')
    clickTracker('CLICK_VIDEO_KENALI_SELENGKAPNYA')

    setTimeout(() => {
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - 72 : 0,
      })
    }, 100)
  }

  return (
    <section
      role="contentinfo"
      aria-label="Hero"
      className="mt-[72px] w-full flex flex-col justify-between"
    >
      <ImageGrid />

      <div className="min-h-[360px] md:min-h-0 grow bg-gradient-to-b from-[#336AE1] to-[#054BCC] p-6 pb-10 lg:p-8 2xl:p-14 text-center rounded-b-[20px] lg:rounded-b-3xl">
        <div className="mx-auto max-w-7xl">
          <h1 className="mb-2 text-base text-white md:text-2xl 2xl:mb-5">
            Sebagai platform edukasi dan teman penggerak,
          </h1>
          <h2 className="max-w-6xl mx-auto text-[32px] leading-9 md:text-5xl md:leading-tight lg:text-[64px] lg:leading-[72px] text-center text-white font-semibold mb-4 lg:mb-8 2xl:mb-10">
            PMM meningkatkan kualitas kompetensi pendidik di Indonesia
          </h2>

          <div className="flex flex-wrap justify-center gap-y-3 md:gap-y-0 gap-x-0 md:gap-x-4">
            <LazyMotion features={domAnimation}>
              <m.div
                initial={{ opacity: 0, y: '5%' }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3, delay: 0.2 }}
                className="order-first w-full md:w-auto md:order-last"
              >
                <Button
                  color="white"
                  size="lg"
                  variant="solid"
                  className="w-full gap-x-1 !rounded-full !text-interactive-default !text-[17px]"
                  onClick={onLearnMoreClicked}
                >
                  Kenali PMM selengkapnya
                  <Icon
                    fontSize="small"
                    className="!text-interactive-default animate-bounce"
                  >
                    arrow_downward
                  </Icon>
                </Button>
              </m.div>
            </LazyMotion>

            <LazyMotion features={domAnimation}>
              <m.div
                initial={{ opacity: 0, y: '5%' }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3, delay: 0.2 }}
                className="order-last w-full md:w-auto md:order-first"
              >
                <Button
                  color="white"
                  size="lg"
                  variant="ghost"
                  className="w-full gap-x-1 !rounded-full !border-2 !border-white !outline-none hover:!bg-transparent focus:!bg-transparent !text-white !text-[17px]"
                  onClick={() => {
                    setPopupVideoOpened(!isPopupVideoOpened)
                    clickTracker('CLICK_VIDEO_KILAS_BALIK')
                  }}
                  style={{ border: '1px solid #fff' }}
                >
                  Video kilas balik PMM
                  <Icon fontSize="small" className="!text-white">
                    play_circle
                  </Icon>
                </Button>
              </m.div>
            </LazyMotion>
          </div>
        </div>
      </div>

      <PopupVideo
        isOpen={isPopupVideoOpened}
        setIsOpen={setPopupVideoOpened}
        videoId={HERO_VIDEO_ID}
      />
    </section>
  )
}
