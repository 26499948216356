import React from 'react'
import clsx from 'clsx'
import debounce from 'debounce'
import NextImage from 'next/image'
import NextLink from 'next/link'
import { LazyMotion, m } from 'framer-motion'
import { isAndroid } from 'react-device-detect'

import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text-v1'

import type { PropsLandingPageTracker } from 'types/landing-page'

const domAnimation = () =>
  import('app/LandingV2Page/utils/domAnimation').then((res) => res.default)

const OPEN_PMM_LINK = isAndroid
  ? 'intent://guru.kemdikbud.go.id/#Intent;scheme=https;package=id.belajar.app;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails?id=id.belajar.app;end'
  : '/home'

export const Navbar = ({ clickTracker }: PropsLandingPageTracker) => {
  const [prevScrollPos, setPrevScrollPos] = React.useState(0)
  const [visible, setVisible] = React.useState(true)

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    )

    setPrevScrollPos(currentScrollPos)
  }, 50)

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos, visible, handleScroll])

  return (
    <nav
      className={clsx(
        'fixed w-full top-0 bg-white shadow flex justify-center z-10 transition ease-in-out delay-100'
        // TODO: uncomment once confirmed by Design team
        // visible && 'translate-y-0',
        // !visible && '-translate-y-32'
      )}
    >
      <LazyMotion features={domAnimation}>
        <m.div
          className="w-full max-w-7xl px-4 md:px-6 2xl:px-0 flex items-center justify-between !min-h-[72px]"
          initial={{ opacity: 0, y: '10%' }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          role="menu"
        >
          <NextLink href="/" passHref>
            <a className="flex items-center" role="menuitem">
              <div className="w-[40px] h-[40px]">
                <NextImage
                  src="/images/logo-tutwurihandayani-big.png"
                  alt="Logo Tut Wuri Handayani"
                  width={40}
                  height={40}
                  priority
                />
              </div>
              <div className="w-[80px] h-12 ml-4 md:ml-5 flex flex-col justify-center">
                <NextImage
                  src={'/images/logo2x.png'}
                  alt="Logo Merdeka Mengajar"
                  width={100}
                  height={40}
                  priority
                />
              </div>
            </a>
          </NextLink>

          <div className="hidden lg:block">
            <Text variant="heading-sm">
              Selamat datang di PMM, Platform Merdeka Mengajar
            </Text>
          </div>

          <NextLink href={OPEN_PMM_LINK} passHref prefetch={false}>
            <Button
              data-testid="landing-open-pmm-button"
              color="blue"
              size="md"
              variant="solid"
              role="menuitem"
              onClick={() => clickTracker('CLICK_ENTER_PMM')}
            >
              Buka PMM
            </Button>
          </NextLink>
        </m.div>
      </LazyMotion>
    </nav>
  )
}
