export const KURIKULUM_MERDEKA_DATA = [
  {
    image: '/images/landing/kurikulum-merdeka-book-icon.png',
    title: 'Pengenalan Kurikulum Merdeka',
    description: 'Lihat Panduan, Referensi, Fakta dan Perbandingan Kurikulum.',
    link: '/tentang-kurikulum-merdeka',
    slug: 'tentang-km',
  },
  {
    image: '/icon/document-collection.svg',
    title: 'Panduan, Regulasi, dan Inspirasi Penerapan',
    description: 'Peningkatan Kompetensi, Pengelolaan Kinerja, dan lain-lain',
    link: '/dokumen',
    slug: 'kumpulan-dokumen',
  },
]
