export const PRODUCTS_BY_GROUP = [
  {
    group: 'Pengembangan diri',
    pageElement: 'SECTION_PENGEMBANGAN_DIRI',
    children: [
      {
        key: 'pelatihan-mandiri',
        link: '/pelatihan-mandiri',
        title: 'Pelatihan Mandiri',
        description:
          'Materi tentang Kurikulum Merdeka dan seputarnya. Singkat dan praktis. Pelajari kapan pun, di mana pun.',
        activityType: 'CLICK_MICRO_LEARNING',
      },
      {
        key: 'komunitas',
        link: '/komunitas?source=HOMEPAGE',
        title: 'Komunitas',
        description:
          '19.000+ komunitas guru dan tenaga kependidikan berbagi praktik baik tentang Kurikulum Merdeka.',
        externalLink: true,
        activityType: 'CLICK_LEARNING_COMMUNITY',
      },
      {
        key: 'uji-kompetensi',
        link: '/pemetaan-kompetensi?source=landing',
        title: 'Uji Kompetensi',
        description:
          'Dapatkan sertifikat kelulusan Uji Kompetensi sebagai syarat Pengangkatan Jabatan Fungsional (KF) Guru.',
        activityType: 'CLICK_UJI_KOMPETENSI',
      },
      {
        key: 'refleksi-kompetensi',
        link: '/refleksi-kompetensi?source=landing',
        title: 'Refleksi Kompetensi',
        description:
          'Rekomendasi pembelajaran yang disusun secara personal, khusus untuk Anda.',
        activityType: 'CLICK_REFLEKSI_KOMPETENSI',
      },
      {
        key: 'seleksi-kepsek',
        link: '/bcks/selection',
        title: 'Seleksi Kepala Sekolah',
        alternateTitle: 'Seleksi',
        description:
          'Informasi persyaratan, tahapan, dan pengecekan kualifikasi Anda dalam memenuhi kriteria seleksi.',
        activityType: 'CLICK_SELEKSI_KEPALA_SEKOLAH',
      },
      {
        key: 'lms',
        link: `${process.env.NEXT_PUBLIC_LMS_URL}/login/openid_connect`,
        title: 'LMS',
        alternateTitle: 'LMS',
        description:
          'Pelatihan terbimbing menggunakan LMS. Anda perlu terdaftar dalam pelatihan untuk mengakses fitur ini.',
        activityType: 'CLICK_LMS_HOMEPAGE_ENTRY_POINT',
      },
      {
        key: 'pengelolaan-kinerja',
        link: '/pengelolaan-kinerja',
        title: 'Pengelolaan Kinerja',
        alternateTitle: 'Pengelolaan Kinerja',
        description:
          'Peningkatan kualitas pembelajaran dan sekolah melalui perencanaan, pelaksanaan, dan penilaian kinerja.',
        activityType: 'CLICK_PENGELOLAAN_KINERJA',
      },
    ],
  },
  {
    group: 'Inspirasi',
    pageElement: 'SECTION_INSPIRASI',
    children: [
      {
        key: 'video-inspirasi',
        link: '/video-inspirasi',
        title: 'Video Inspirasi',
        description:
          'Kumpulan video dari sumber terpilih untuk membantu memahami dan menerapkan Kurikulum Merdeka.',
        activityType: 'CLICK_VIDEO_INSPIRATION',
      },
      {
        key: 'bukti-karya',
        link: '/bukti-karya',
        title: 'Bukti Karya',
        description:
          'Rekam jejak karya, kinerja, dan kompetensi pendidik. Kesempatan berinteraksi dan bertukar pikiran.',
        externalLink: true,
        activityType: 'CLICK_BUKTI_KARYA',
      },
      {
        key: 'ide-praktik',
        link: '/ide-praktik',
        title: 'Ide Praktik',
        description:
          'Artikel dan video terpilih untuk membantu Ibu Bapak Guru praktik pembelajaran terpusat pada murid.',
        activityType: 'CLICK_IDE_PRAKTIK',
      },
    ],
  },
  {
    group: 'Mengajar',
    pageElement: 'SECTION_MENGAJAR',
    children: [
      {
        key: 'asesmen',
        link: '/assessment',
        title: 'Asesmen Murid',
        description:
          'Paket soal daring/luring, dengan fitur pemeriksaan otomatis dan analisis hasil yang mendalam.',
        activityType: 'CLICK_ASSESSMENT_PAGE',
      },
      {
        key: 'perangkat-ajar',
        link: '/perangkat-ajar',
        title: 'Perangkat Ajar',
        description:
          'Modul ajar, modul projek, buku teks, dan bahan ajar lainnya. Dikurasi oleh Kemendikbudristek.',
        activityType: 'CLICK_TOOLKIT_PAGE',
      },
      {
        key: 'cp-atp',
        link: '/kurikulum/referensi-penerapan/capaian-pembelajaran/',
        title: 'CP & ATP',
        description:
          'Capaian Pembelajaran dan contoh Alur Tujuan Pembelajaran (ATP) sesuai Mata Pelajaran Anda.',
        activityType: 'CLICK_CP_ATP',
      },
    ],
  },
]
