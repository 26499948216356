import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { Icon } from '@wartek-id/icon'

import styles from './PopupVideo.module.css'

import type { Dispatch, SetStateAction } from 'react'

interface PageProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  videoId: string
}

// This component is used for Youtube Video Popup

function PopupVideo({ isOpen, setIsOpen, videoId }: PageProps) {
  const videoURL = `https://www.youtube.com/embed/${videoId}`
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-scroll"
          onClose={() => setIsOpen((isOpen) => !isOpen)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block h-[300px] w-full max-w-5xl transform bg-transparent text-left align-middle shadow-xl transition-all md:h-[500px]">
                <div className={styles.closeButton}>
                  <Icon
                    as="i"
                    fontSize="small"
                    color="inverse"
                    aria-hidden="true"
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                  >
                    close
                  </Icon>
                </div>
                <iframe
                  className="h-full w-full bg-gray-50"
                  src={videoURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default PopupVideo
