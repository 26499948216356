export const CONTENT_PLAYLISTS_DATA = [
  {
    topicId: '84',
    key: 'playlist-1',
    contentCount: '3 ARTIKEL, 4 VIDEO',
    link: 'https://guru.kemdikbud.go.id/pelatihan-mandiri/contextualized-learning/84',
    title: 'Pahami Merdeka Belajar dan Kurikulum Merdeka',
  },
  {
    topicId: '86',
    key: 'playlist-2',
    contentCount: '3 VIDEO, 1 DOKUMEN',
    link: 'https://guru.kemdikbud.go.id/pelatihan-mandiri/contextualized-learning/86',
    title: 'Asesmen dan Pembelajaran dalam Kurikulum Merdeka',
  },
  {
    topicId: '88',
    key: 'playlist-3',
    contentCount: '4 VIDEO, 1 DOKUMEN',
    link: 'https://guru.kemdikbud.go.id/pelatihan-mandiri/contextualized-learning/88',
    title:
      'Profil Pelajar Pancasila dan Projek Penguatan Profil Pelajar Pancasila',
  },
  {
    topicId: '89',
    key: 'playlist-4',
    contentCount: '5 VIDEO',
    link: 'https://guru.kemdikbud.go.id/pelatihan-mandiri/contextualized-learning/89',
    title: 'Sikap dan Cara Pikir dalam Mengimplementasikan Kurikulum Merdeka',
  },
]
