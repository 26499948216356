export const HERO_VIDEO_ID = 'BnPpUwzDO0Q'

export const TESTIMONY_VIDEO_DATA = [
  {
    image: '/images/landing/testimony-1.jpg',
    content:
      'Adanya menu Perangkat Ajar di PMM memudahkan saya mencari perangkat ajar yang menarik untuk diterapkan di kelas sesuai kebutuhan murid. Sekarang murid-murid saya pun senang karena merasa pembelajarannya berbeda dengan yang dulu.',
    creator: 'Pak Yoki Iskandar, S.Pd.',
    creatorSubject: 'Guru Matematika, SMPN Karang Pucung',
    videoId: 'xA0Wjj14Nrk',
  },
  {
    image: '/images/landing/testimony-2.jpg',
    content:
      'Dalam implementasi Kurikulum Merdeka ada banyak ketidaktahuan dan hal yang belum dikuasai, fitur Komunitas Belajar adalah solusinya. Saya bisa bertemu dengan 100 penggerak komunitas lain untuk belajar, berbagi, memotivasi dan menginspirasi.',
    creator: 'Bu Kasmiyana Sulistiowati, M.Pd.',
    creatorSubject: 'Kepala Sekolah, SMPN 2 Karang Pucung',
    videoId: '9wpwbKs2l54',
  },
  {
    image: '/images/landing/testimony-3.jpg',
    content:
      'Platform Merdeka Mengajar ini seperti cinta pertama saya dalam mengajar. Dulu saya harus mencari materi kesana kemari, sekarang saya bisa dapat inspirasi dan pengalaman untuk mengembangkan diri melalui menu Pelatihan Mandiri.',
    creator: 'Pak Abdul Rahmat',
    creatorSubject: 'Guru Kelas 4, SDN 001 Balikpapan Tengah',
    videoId: 'lGbYDgu3Y6M',
  },
]
