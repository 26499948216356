import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

export const useSession = () => {
  const [session] = useLocalStorage(APP_LOGIN_STORAGE_KEY, null)

  let hasSession = false

  if (Boolean(session)) {
    const sessionExpiryTime = new Date(session.expiredAt).getTime()
    const currentTime = new Date().getTime()
    const isSessionExpired = sessionExpiryTime < currentTime

    hasSession = Boolean(session) && !isSessionExpired
  }

  return {
    hasSession,
  }
}
